<template>
  <div>
    <!--1 背景图 -->
    <bigbg
      url="static/srbanner/yawOuQUP.webp"
      bigtext="品牌营销"
      smalltext="利用大数据及传播管控，为企业经营提供打假、合规经营、营销拓展等服务方案优势"
    ></bigbg>
    <!-- 2安全隐私服务 -->
    <div class="fourbig">
      <h2>安全隐私服务</h2>
      <p>
        基于网络数据感知、大数据分析、AI
        技术，构建覆盖数据获取引擎、数据分析中台到上层应用的全链路产品，通过大数据情报服务为企业的经营行为保驾护航。
      </p>
      <!-- 左右布局 -->
      <div class="bigboxss">
        <!-- 1 -->
        <div class="onebox">
          <div class="imgs"></div>
          <div class="box">
            <p>网络内容合规</p>
            <div>
              对输入的网站进行全方位的扫描，识别其中的涉黄、涉赌、诈骗、虚假信息等违法违规内容，发现网站内的违法违规链接，方便及时处理，降低监管风险。
            </div>
          </div>
        </div>
        <div style="height:30px"></div>
        <div class="onebox">
          <div class="box">
            <p>App 隐私检测</p>
            <div>
              对指定的 App，通过模拟安装、运行，扫描，识别 App
              的行为并做违规判断，确保其安装和运行过程中的行为符合监管要求，输出违规行为点。
            </div>
          </div>
          <div class="images"></div>
        </div>
        <!-- 2 -->
        <div style="height:30px"></div>
        <div class="onebox">
          <div class="imgs3"></div>
          <div class="box">
            <p>小程序隐私合规</p>
            <div>
              对指定的小程序，通过沙箱运行，静态扫描等方式，识别小程序的行为并做违规判断，确保其运行过程中的行为以及隐私政策符合监管要求，输出分析报告。
            </div>
          </div>
        </div>
        <div style="height:30px"></div>
        <div class="onebox">
          <div class="box">
            <p>App 安全服务</p>
            <div>
              扫描指定的 App，发现 App
              相关安全风险。提供病毒检测、恶意吸费检测、强行捆绑无关软件检测、漏洞扫描、漏洞代码溯源等安全服务。
            </div>
          </div>
          <div class="images3"></div>
        </div>
        <!-- 3 -->
        <div style="height:30px"></div>
        <div class="onebox">
          <div class="imgs4"></div>
          <div class="box">
            <p>网站拦截预警</p>
            <div>
              对指定网站进行动态监测，及时预警被拦截的违规链接。
            </div>
          </div>
        </div>
        <div style="height:30px"></div>
        <div class="onebox">
          <div class="box">
            <p>网站拦截申述处理</p>
            <div>
              对已经风险提示的网站，提供高效专业的申诉处理服务。
            </div>
          </div>
          <div class="images4"></div>
        </div>
      </div>
    </div>
    <!-- 3 广告经营服务 1252px -->
    <div class="threebg">
      <h2>广告经营服务</h2>
      <p>
        基于数据感知及大数据挖掘技术，为广告主以及广告代理机构提供实时的广告创意追踪、广告投放效果分析、广告情报分析等服务。
      </p>
      <!-- 图片 -->
      <div class="imgbox">
        <div class="onebig1">
          <div class="iconone"></div>
          <div class="bigtext">广告素材/落地页智能检索</div>
          <div class="smalltext">
            根据广告关键词、广告类型、广告投放渠道、落地页关键词等多维条件，检索广告文案、图片、视频、落地页。根据广告传播热度，辅助创作者进行创意设计。
          </div>
        </div>
        <div class="onebig1">
          <div class="icontwo"></div>
          <div class="bigtext">广告投放效果评估</div>
          <div class="smalltext">
            通过大数据智能感知，评估广告投放的曝光率、点击率，为企业制定广告投放计划。
          </div>
        </div>
        <div class="onebig1">
          <div class="iconthree"></div>
          <div class="bigtext">App 推广榜单</div>
          <div class="smalltext">
            通过大数据计算平台，计算每款 App
            的广告投放量，筛选出广告投放量TOP1000的 App，供 App
            开发者及企业参考。
          </div>
        </div>
      </div>
      <div class="imgboxs2">
        <div class="onebig1">
          <div class="iconone"></div>
          <div class="bigtext">公众号推广榜单</div>
          <div class="smalltext">
            通过大数据计算平台，计算每个公众号的广告投放量，筛选出广告投放量TOP1000的公众号，供公众号拥有者及广告投放企业参考。
          </div>
        </div>
        <div style="width:30px"></div>
        <div class="onebig1">
          <div class="icontwo"></div>
          <div class="bigtext">App 开发商榜单</div>
          <div class="smalltext">
            以 App
            对应的开发商为对象，计算每一个开发商的广告投放量，筛选出广告投放量TOP1000的
            App 开发商，供 App 开发商进行广告投放参考。
          </div>
        </div>
      </div>
    </div>
    <!-- 5底部 -->
    <Header></Header>
    <Footer></Footer>
  </div>
</template>
<script>
import bigbg from "../component/bigbg.vue";
// 导入头部底部组件
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "brand_mark",
  data() {
    return {};
  },
  components: {
    bigbg,
    Header,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
//1

//2
.fourbig {
  height: 2494px;

  padding-top: 80px;
  h2 {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  p {
    margin: 0 auto;
    width: 1197px;
    margin-top: 24px;
    font-size: 20px;
    font-weight: 400;
    color: #565656;
    line-height: 30px;
    letter-spacing: 1px;

    margin-bottom: 60px;
  }
  .bigboxss {
    width: 1200px;
    margin: 0 auto;
    .onebox {
      display: flex;
      width: 1200px;
      height: 324px;
      background: #ffffff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      .imgs {
        width: 600px;
        height: 324px;
        background-image: url("./photo/Zn02y4nj.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
      }
      .box {
        width: 600px;
        height: 324px;
        p {
          margin-top: 64px;
          margin-left: 48px;
          margin-bottom: 24px;
          width: 600px;
          font-size: 32px;

          font-weight: 500;
          color: #333333;
          letter-spacing: 1px;
          text-align: left;
        }
        div {
          margin-top: 64px;
          margin-left: 48px;
          margin-bottom: 24px;
          width: 504px;
          line-height: 30px;
          font-size: 20px;

          font-weight: 400;
          color: #999999;

          text-align: left;
        }
      }
      .images {
        width: 600px;
        height: 324px;
        background-image: url("./photo/KTg2N5Fe.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
      }
      .imgs3 {
        width: 600px;
        height: 324px;

        background: url("./photo/4aw7lRNp.webp") no-repeat center;
        background-size: 100% 100%;
      }
      .images3 {
        width: 600px;
        height: 324px;
        background: url("./photo/X7xtYRGg.webp") no-repeat center;
        background-size: 100% 100%;
      }
      .imgs4 {
        width: 600px;
        height: 324px;

        background: url("./photo/IVtgYFhY.webp") no-repeat center;
        background-size: 100% 100%;
      }
      .images4 {
        width: 600px;
        height: 324px;
        background: url("./photo/OwMXK7Uc.webp") no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
}
//3
.threebg {
  height: 1252px;
  background: #f6f8fb;
  padding-top: 80px;
  h2 {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
  }
  p {
    margin: 0 auto;
    width: 1197px;
    margin-top: 24px;
    line-height: 30px;
    font-size: 22px;
    font-weight: 400;
    color: #565656;
    letter-spacing: 1px;
  }
  .imgbox {
    margin-top: 60px;
    margin-bottom: 30px;
    width: 1200px;
    margin-left: 360px;
    display: flex;
    justify-content: space-between;
    .onebig1 {
      padding-top: 64px;
      width: 380px;
      height: 411px;
      background: #ffffff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      .bigtext {
        font-size: 24px;

        font-weight: 500;
        color: #333333;
        margin-bottom: 12px;
      }
      .smalltext {
        padding-left: 36px;
        padding-right: 36px;
        text-align: left;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        color: #999999;
      }
      .iconone {
        width: 92px;
        height: 92px;
        margin: 0 auto;
        margin-bottom: 16px;
        background: url("./photo/threeone.png") no-repeat center;
        background-size: 100% 100%;
      }
      .icontwo {
        width: 92px;
        height: 92px;
        margin: 0 auto;
        margin-bottom: 16px;
        background: url("./photo/threetwo.png") no-repeat center;
        background-size: 100% 100%;
      }
      .iconthree {
        width: 92px;
        height: 92px;
        margin: 0 auto;
        margin-bottom: 16px;
        background: url("./photo/threethree.png") no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
  .imgboxs2 {
    display: flex;
    justify-content: center;
    .onebig1 {
      padding-top: 64px;
      width: 380px;
      height: 411px;
      background: #ffffff;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      .bigtext {
        font-size: 24px;

        font-weight: 500;
        color: #333333;
        margin-bottom: 12px;
      }
      .smalltext {
        padding-left: 36px;
        padding-right: 36px;
        text-align: left;
        font-size: 20px;

        font-weight: 400;
        color: #999999;
      }
      .iconone {
        width: 92px;
        height: 92px;
        margin: 0 auto;
        margin-bottom: 16px;
        background: url("./photo/threefour.png") no-repeat center;
        background-size: 100% 100%;
      }
      .icontwo {
        width: 92px;
        height: 92px;
        margin: 0 auto;
        margin-bottom: 16px;
        background: url("./photo/threefive.png") no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
